import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { withPrefix } from "gatsby";
import Zendesk from "react-zendesk";
const samplePageLinks = [
  {
    text: "Terms & Conditions",
    url: "../terms-of-use",
    badge: false,
    description:
      "A simple example of linking to another page within a Gatsby site",
  },
  { text: "Privacy", url: "../privacy-policy" },
  { text: "Cookie Policy", url: "../cookie-policy" },
];
const setting = {
  color: {
    theme: "#000"
  },
  launcher: {
    chatLabel: {
      "en-US": "Need Help"
    }
  },
  contactForm: {
    fields: [
      { id: "description", prefill: { "*": "My pre-filled description" } }
    ]
  }
};
const Footer1 = ({ siteTitle }) => (
  <footer className="footer-main">
    <div className="container">
      <div className="icons">
        <img src={withPrefix("img/icons-ai.svg")} alt="icons-ai" />
      </div>
      <div className="brand">
        <Link to="/" title="99 Generations">
          <img
            src="/assets/img/brand-99-generations.svg"
            alt="brand-99-generations"
          />
        </Link>
      </div>
      <div className="foot-sub-title">By robots, for humans.™</div>
      <div className="foot-wrap">
        <p>©{new Date().getFullYear()}</p>
        <div className="foot-menu2">
          {samplePageLinks.map((link, index) => (
            <React.Fragment key={index}>
              <Link to={link.url}>{link.text}</Link>
              {index !== samplePageLinks.length - 1 && <> · </>}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
    {/* <Zendesk defer zendeskKey={'b8a7b9fe-8b45-46c7-b93f-3f9d2722ef4c'} {...setting} onLoaded={() => {}} /> */}
  </footer>
);

Footer1.propTypes = {
  siteTitle: PropTypes.string,
};

Footer1.defaultProps = {
  siteTitle: ``,
};

export default Footer1;

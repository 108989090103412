import * as React from "react";
import Helmet from "react-helmet";
import { withPrefix } from "gatsby";

const Header1 = () => (
  <header className="header-main">
    <Helmet>
      <title>{`99 Generations | By robots, for humans.™`}</title>
    </Helmet>
    <div className="container">
      <div className="brand">
        <a href="../" title="99 Generations">
          <img
            src={withPrefix("img/brand-99-generations.svg")}
            alt="brand-99-generations"
          />
        </a>
      </div>
      <div className="overlay" />
    </div>
  </header>
);

export default Header1;
 